import * as React from 'react'
import {
  Datagrid,
  List,
  Filter,
  TextField,
  TextInput,
  DateField,
  BulkDeleteButton,
} from 'react-admin'

const PostBulkActionButtons = (props) => {
  const { category } = JSON.parse(localStorage.getItem('user'))

  return <>{category.includes('super-admin') && <BulkDeleteButton {...props} />}</>
}

const MeasurementsFilter = props => (
  <Filter {...props}>
    <TextInput label="Email do usuário" source="name" alwaysOn />
  </Filter>
)

const MeasurementsList = props => (
  <List
    {...props}
    title="Medições"
    bulkActionButtons={<PostBulkActionButtons />}
    filters={<MeasurementsFilter />}
    pagination={null}
    sort={{ field: 'datetime', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="userName" label="Nome" sortable={false} />
      <TextField source="userEmail" label="Email" sortable={false} />
      <DateField label="Data e Hora" source="datetime" locales="pt-BR" showTime />
      <TextField label="Valor" source="value" />
    </Datagrid>
  </List>
)

export default MeasurementsList
