import firebase from 'firebase'
import 'firebase/firestore'

import { firebaseConfig } from '../utils/constants'

firebase.initializeApp(firebaseConfig.firebaseConfig)
const db = firebase.firestore()
const storage = firebase.storage().ref()
const auth = firebase.auth()

export { db, storage, auth }
