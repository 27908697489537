import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'

import { SUPER_ADMIN, ADMIN, APP_USER } from '../../utils/constants'

const UsersCreate = (props) => {
  const { category } = JSON.parse(localStorage.getItem('user'))
  const isSuperAdmin = category.includes(SUPER_ADMIN)
  const isAdmin = category.includes(ADMIN)

  const categoryInputChoices = () => {
    if (isSuperAdmin) {
      return [{ id: SUPER_ADMIN, name: 'Super Admin' }, { id: ADMIN, name: 'Admin' }, { id: APP_USER, name: 'App User' }]
    }

    if (isAdmin) {
      return [{ id: APP_USER, name: 'App User' }]
    }

    return []
  }

  const appUserChoices = [
    { id: 'app-user', name: 'Sim' },
    { id: 'not-app-user', name: 'Não' },
  ]

  return (
    <Create {...props} title="Usuários">
      <SimpleForm>
        <TextInput label="Nome" source="name" />
        <TextInput label="Email" source="email" type="email" />
        <TextInput label="Senha" source="password" type="password" />
        <SelectInput
          label="Categoria"
          source="category"
          choices={categoryInputChoices()}
        />
        <FormDataConsumer>
          {({ formData }) => formData.category !== APP_USER && isSuperAdmin && (
            <SelectInput
              style={{ width: '19%' }}
              label="Usuário do App"
              source="appUser"
              choices={appUserChoices}
              optionValue="id"
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default UsersCreate
