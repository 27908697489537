// Desabilitei pois provavelmente teremos mais funções nesse arquivo
/* eslint-disable import/prefer-default-export */
import { auth } from './firebaseClient'

export const registerUser = (params) => {
  const { email, password } = params.data

  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      auth.sendPasswordResetEmail(email)
      return user.user.uid
    })
    .catch(({ code, message }) => {
      if (code === 'auth/email-already-in-use') {
        throw new Error('O email já está em uso!')
      }

      throw new Error(message)
    })
}

export const resetUserEmail = email => auth.sendPasswordResetEmail(email)
  .then(() => { return true })
  .catch((error) => {
    throw new Error(error)
  })
