import * as React from 'react'
import { Edit, SimpleForm, TextInput, SelectInput, FormDataConsumer } from 'react-admin'
import { Button } from '@material-ui/core'

import { SUPER_ADMIN, ADMIN, APP_USER } from '../../utils/constants'
import { resetUserEmail } from '../../services/helperDataMethods'

const UsersEdit = (props) => {
  const [$passwordReset, setPasswordReset] = React.useState(false)
  const [$loading, setLoading] = React.useState(false)

  const { category } = JSON.parse(localStorage.getItem('user'))
  const isSuperAdmin = category.includes(SUPER_ADMIN)
  const isAdmin = category.includes(ADMIN)

  const categoryInputChoices = () => {
    if (isSuperAdmin) {
      return [
        { id: SUPER_ADMIN, name: 'Super Admin' },
        { id: ADMIN, name: 'Admin' },
        { id: APP_USER, name: 'App User' },
      ]
    }

    if (isAdmin) {
      return [{ id: APP_USER, name: 'App User' }]
    }

    return []
  }

  const appUserChoices = [{ id: true, name: 'Sim' }, { id: false, name: 'Não' }]

  const handlePasswordReset = async (email) => {
    setLoading(true)
    const response = await resetUserEmail(email)

    if (response) {
      setPasswordReset(true)
    }

    setLoading(false)
  }

  const handleButtonText = () => {
    if ($loading) {
      return 'Enviando e-mail'
    }

    if ($passwordReset) {
      return 'E-mail enviado'
    }

    return 'Redefinir senha'
  }

  return (
    <Edit {...props} title="Usuários">
      <SimpleForm>
        <TextInput label="Nome" source="name" />
        <TextInput label="Email" source="email" type="email" />
        <SelectInput
          label="Categoria"
          source="category"
          choices={categoryInputChoices()}
          optionValue="id"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {formData.category !== APP_USER && isSuperAdmin && (
                <SelectInput
                  style={{ width: '19%' }}
                  label="Usuário do App"
                  source="appUser"
                  choices={appUserChoices}
                  optionValue="id"
                />
              )}
              <Button
                variant="contained"
                disabled={$passwordReset || $loading}
                onClick={() => handlePasswordReset(formData.email)}
                {...rest}
              >
                {handleButtonText()}
              </Button>
            </div>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}

export default UsersEdit
